
.card-item {
  margin-bottom: 50px;

  .action-item {
    .action-item-btn {
      display: flex;
      background-color: #f0f2f5;
      margin: 20px;
      padding: 10px;
      border-radius: 3px;
      cursor: pointer;

      .action-icon {
        font-size: 36px;
        margin: 6px 10px;
        color: #52c41a;
      }

      .title {
        font-size: 14px;
      }

      .desc {
        color: #999999;
      }
    }
  }

  .weixin-url {
    margin: 40px 0;
  }
}

@primary-color: #7f7fd5;@link-color: #86a8e7;