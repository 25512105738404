.card-item {
  margin-bottom: 50px;
}
.card-item .action-item .action-item-btn {
  display: flex;
  background-color: #f0f2f5;
  margin: 20px;
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}
.card-item .action-item .action-item-btn .action-icon {
  font-size: 36px;
  margin: 6px 10px;
  color: #52c41a;
}
.card-item .action-item .action-item-btn .title {
  font-size: 14px;
}
.card-item .action-item .action-item-btn .desc {
  color: #999999;
}
.card-item .weixin-url {
  margin: 40px 0;
}
